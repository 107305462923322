.crumb {
  width: 100%;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex: 1;
  img {
    height: 30px;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
  }
  ul {
    display: flex;
    li {
      margin-right: 10px;
      cursor: pointer;
      font-size: 14px;
      
      &.active {
        color: #2b99ff;
      }
    }
  }
  .selected {
    color: #1890FF;
    cursor: pointer;
  }
}