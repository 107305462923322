.lite {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  .svg-box {
    position: relative;
    img {
      width: 100%;
    }
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      rect {
        cursor: pointer;
        &.active {
          stroke: #3385ff;
          stroke-width: 4px;
          stroke-dasharray: 0;
          stroke-linejoin: round;
        }
      }
      
    }
  }
  .img-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    span {
      display: block;
      width: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); 
      margin-bottom: 16px;
      i {
        cursor: pointer;
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      u {
        display: block;
        height: 1px;
        overflow: hidden;
        background: #D2D2D2;
      }
    }
  }
}

.standard {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  .svg-box {
    position: relative;
    image-rendering: -webkit-optimize-contrast;
    -webkit-font-smoothing: antialiased;
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text {
        cursor: pointer;
        font-weight: bold;
        &::selection {
          background: transparent;
        }
      }
    }
  }
  .img-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    span {
      display: block;
      width: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); 
      margin-bottom: 16px;
      i {
        cursor: pointer;
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      u {
        display: block;
        height: 1px;
        overflow: hidden;
        background: #D2D2D2;
      }
    }

    .mask {
      position: relative;
      .range {
        position: absolute;
        display: none;
        left: -139px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); 
        padding: 4px;
        input {
          width: 130px;
        }
      }
      &:hover {
        .range {
          display: block;
        }
      }
    }
  }
}