.main {
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #e8eff9;
}
.header {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80%;
    }
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .user {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 10px;
    em {
      font-style: normal;
      margin: 0 5px;
    }
  }
}
.menu {
  width: 160px;
  height: 100%;
  position: fixed;
  top: 0;
  padding-top: 50px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  .item {
    h2 {
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      line-height: 32px;
      margin-top: 2px;
      cursor: pointer;
      em {
        font-style: normal;
        flex: 1;
        padding-left: 8px;
      }
      span {
        font-size: 12px;
      }
      &.active {
        background-color: #096BED;
        box-shadow: 0 1px 3px 0 #004aad66;
        border-radius: 4px;
        color: #ffffff;
      }
    }
    ul {
      li {
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 5px;
        &.active {
          span {
            background: #096BED;
            background-color: #F1F6FA;
            border-radius: 4px;
            position: relative;
            &::before {
              position: absolute;
              content: "";
              display: inline-block;
              width: 2px;
              height: 14px;
              background-color:  #096BED;
              left: 10px;
              top: 6px;
              
            }
          }
        }
        span {
          width: 144px;
          display: inline-block;
          padding: 0 12px 0 20px;
        }
      }
    }
  }
}
.content {
  padding: 50px 10px 0 160px;
  // width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}