.repairdoc {
  background-color: #ffffff;
  min-height: calc(100vh - 60px);
  position: relative;
  .list {
    .item {
      .item-left {
        width: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .item-right {
        flex: 1;
        border-radius: 4px;
        border: 1px solid #E9E9E9;
        width: 100%;
        min-height: 140px;
        position: relative;
        .title {
          height: 40px;
          color: #333333;
          background-color: #FAFAFB;
        }
        .operate {
          position: absolute;
          right: 15px;
        }
      }
    }
  }
  .tools {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 -1px 2px 0 #0000001f;
  }
}