.items {
  .items-head {
    background-color: #F8F8F8;
    color: #808080;
    height: 40px;
    display: flex;
    align-items: center;
    >div {
      text-align: center;
    }
  }
  .items-body {
    .item {
      display: flex;
      align-items: center;
      height: 40px;
      border: 1px solid #EEE;
      text-align: center;
    }
  }
}

