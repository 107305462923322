.partsprice {
  margin: 0 20px;
  display: flex;
  .l {
    width: 250px;
    .basic {
      border: 1px solid #d2d2d2;
      .img {
        height: 250px;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
          width:auto;
          height:auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%,-50%,0);
          cursor: pointer;
        }
        .model {
          height: 100%;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        .tools,
        .watch {
          position: absolute;
          width: 100%;
          background: rgba($color: #2a98fe, $alpha: .8);
          bottom: 0;
          z-index: 9;
          height: 36px;
          line-height: 36px;
          color: #fff;
          text-align: center;
          span {
            cursor: pointer;
          }
          
          // display: flex;
          // span {
          //   flex: 1;
          //   text-align: center;
          //   line-height: 36px;
          //   color: #fff;
          //   cursor: pointer;
          // }
        }
        .watch {
          background: rgba($color: #fff, $alpha: .8);
          border: 1px solid #2a98fe;
          color: #1890ff;
          i {
            color: #2a98fe;
          }
        }
      }
      .info {
        padding: 10px;
        h3 {
          font-size: 18px;
        }
        p {
          line-height: 20px;
          margin-top: 6px;
        }
        button {
          cursor: pointer;
          font-size: 14px;
          color: #fff;
          line-height: 14px;
          border: none;
          background: #1890ff;
          padding: 4px 6px;
          border-radius: 4px;
          margin-bottom: 4px;
        }
      }
    }
    .channel {
      border: 1px solid #d2d2d2;
      padding: 10px;
      margin-top: 10px;
      .g {
        margin-bottom: 20px;
        h6 {
          font-size: 16px;
          margin-bottom: 6px;
        }
        .menu-item {
          padding: 4px 0;
          display: flex;
          align-items: center;
          img {
            height: 24px;
            display: block;
          }
          .polling {
            width: 120px;
            background: #f0f6fd;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            span {
              width: 24%;
              img {
                width: 80%;
              }
            }
            div {
              width: 80px;
              height: 4px;
              background: #d8d8d8;
              border-radius: 3px;
              span {
                height: 100%;
                background: linear-gradient(90deg,#1890ff, #35c3ff);
                border-radius: 3px;
                display: block;
                transition: all .3s cubic-bezier(.08,.82,.17,1) 0s;
              }
            }
            button {
              border: 0;
              background: transparent;
              text-align: left;
              color: #1890FF;
              cursor: pointer;
              margin-left: 10px;
              em {
                color: #1890FF;
                margin-right: 4px;
              }
              &.success {
                color: #888888;
                em {
                  color: #00B930;
                }
              }
              
              @keyframes antRotate {
                to {
                transform:rotate(405deg)
                }
              }
              &.trans {
                color: #888888;
                em {
                  transform: rotate(45deg);
                  animation: antRotate 1.2s infinite linear;
                  display: inline-block;
                }
              }
            }
          }
          
        }
      }
    }
  }
  .r {
    width: calc(100% - 262px);
    margin-left: 12px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    padding-bottom: 20px;
    .list {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
    }
    .sort {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 12px;
      border-bottom: 1px solid #d9d9d9;
      .sortR {
        display: flex;
        align-items: center;
        ul {
          display: flex;
          margin-left: 10px;
          li {
            border: 1px solid #D2D2D2;
            padding: 6px 7px;
            cursor: pointer;
            &.active {
              background: #1890FF;
              border-color: #1890FF;
              color: #fff;
            }
          }
        }
      }
    }
    .page {
      padding: 20px;
    }
    .empty {
      text-align: center;
      padding-top: 230px;
      img {
        width: 80px;
      }
      p {
        font-size: 14px;
        color: #DEDEDE;
      }
    }
  }
}

.trend {
  padding: 10px;
  h5 {
    font-size: 18px;
    text-align: left;
    color: #000000;
    line-height: 20px;
  }
  .chart {
    height: 600px;
  }
  .no {
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
  }
}

.modal-mask {
  position: absolute;
  background-color: rgba(0,0,0,.45);
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.modal-wrap {
  outline: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  width: 630px;
  box-sizing: border-box;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  margin: 0 auto;
  max-width: calc(100vw - 32px);
  top: 100px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
}
.modal-close-btn {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .iconfont {
    font-size: 16px;
    color: #8E8E8E;
  }
}
.vehicle-brand-wrap {
  padding: 20px;
}
.vehicle-select {
  margin-bottom: 20px;
}
.vehicle-title {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 20px;
}
.vehicle-brand-item {
  margin-left: 10px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  line-height: 20px;
  cursor: pointer;
  &.active {
    color: #2a98fe;
  }
}
.vehicle-table {
  border-top: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  background: #fff;
  max-height: 400px;
  overflow-y: auto;
  thead {
    background: rgba(24,144,255,0.10);
  }
  th, td {
    padding: 10px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    button {
      cursor: pointer;
      font-size: 14px;
      color: #fff;
      padding: 2px 6px;
      border: none;
      background: #1890ff;
      border-radius: 4px;
    }
  }
  .col-1 {
    text-align: center;
    width: 95px;
  }
  .col-2 {
    text-align: left;
    width: 175px;
  }
  .col-3 {
    text-align: left;
    width: 200px;
  }
  .col-4 {
    text-align: center;
    width: 115px;
  }
}
.vehicle-table-h {
  .maxh {
    max-height: 400px;
    overflow-y: auto;
  }
  table {
    background: #E8E8E8;
    border-spacing: 1px;
    width: 100%;
    th {
      background: #eaf5fe;
      padding: 10px;
      text-align: left;
    }
    caption {
      background: #E8E8E8;
      line-height: 30px;
      text-align: left;
      padding: 0 10px;
      font-size: 14px;
    }
    td {
      font-size: 14px;
      text-align: center;
      padding: 16px 0;
      background: #fff;
      word-break: break-all;
      p {
        line-height: 20px;
        vertical-align: middle;
        padding-left: 14px;
        position: relative;
        .us {
          display: inline-block;
          border: solid #d8d8d8;
          border-width: 1px 0 0 1px;
          width: 10px;
          height: 19px;
          position: absolute;
          top: 10px;
          left: 0;
        }

        &:last-child {
          .us {
            border-width: 1px 0 0 0;
          }
        }
      }
      .flex {
        display: flex;
        align-items: center;
        span {
          margin: 0 6px;
        }
      }
      .link {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}