.home {
  .title-con {
    position: relative;
    padding-left: 10px;
    font-size: 14px;
    &::before {
      content: '';
      display: inline-block;
      height: 14px;
      width: 3px;
      background-color: #333333;
      position: absolute;
      left: 0;
      top: 3px;
      border-radius: 2px;
    }
  }
}