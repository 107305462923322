.loading {
  position: relative;
  .opacity {
    width: 100%;
    opacity: 0.4;
    background: #fff;
    content: '';
    pointer-events: none;
  }
  .transition {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 80px;
      height: 80px;
      border: 4px solid #1890ff;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: load 1s infinite linear;
      display: block;
    }
    
    @keyframes load {
      from {
        transform:rotate(0deg);
        -ms-transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
        -ms-transform:rotate(360deg);
      }
    }
  }
  &.min {
    width: 20px;
    height: 20px;
    display: inline-block;
    .transition {
      display: inline-block;
      span {
        width: 16px;
        height: 16px;
        border-width: 2px;
      }
    }
    
  }
  
}


@keyframes rotate{
  100%{
    transform: rotate(720deg) translate3d(0, 0, 0);
  }
}

.general,
.white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 230, 230,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(24, 144, 255, 1);
    border-radius: 50%;
    transform-origin: 5px 15px;
    animation: rotate 2s linear infinite;
    &::before{
      position: absolute;
      width: 10px;
      height: 10px;
      background: rgba(24, 144, 255, 0.35);
      border-radius: 50%;
      left: -8.66px;
      top: 15px;
      content: "";
      display: inline-block;
    }
    &::after{
      position: absolute;
      width: 10px;
      height: 10px;
      background: rgba(24, 144, 255, 0.65);
      border-radius: 50%;
      left: 8.66px;
      top: 15px;
      content: "";
      display: inline-block;
    }
  }
}

 
.white {
  span {
    background: rgba(255, 255, 255, .4);
  }
  span::before {
    background: rgba(255, 255, 255, .8);
  }
  span::after {
    background: rgba(255, 255, 255, .6);
  }
}

