.model {
  // padding: 0 10px;
  .content {
    background-color: #ffffff;
    padding: 15px;
    margin-top: 8px;
    border-radius: 4px;
    min-height: calc(100vh - 130px);
    .list {
      border: 1px solid #E9E9E9;
      .item {
        border: 1px solid #E9E9E9;
      }
      .brand-logo {
        width: 76px;
        height: 46px;
        background-color: #ffffff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          max-width: 70px;
          max-height: 40px;
        }
      }
      
    }
  }
}