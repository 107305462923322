.login {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: right;
  .box {
    width: 410px;
    height: 448px;
    text-align: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #FFF;
    background: rgba($color: #fff, $alpha: 0.8);
    box-shadow: 0 0 10px 0 #FFF inset, 0 0 10px 0 #0000001f;
    margin-right: 100px;
    img {
      margin: 40px 0;
    }
    .input {
      width: 90%;
      margin: 0 auto 20px;
    }
    .button {
      width: 90%;
      margin: 40px auto 0;
    }
  }
  .footer {
    position: fixed;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}