
.role {
  min-height: calc(100vh - 60px);
  background-color: #fff;
  display: flex;
  .role-lf {
    width: 130px;
    .tab-list {
      margin-top: 10px;
      border-radius: 6px;
      border: 1px solid #E9E9E9;
      background: #FFF;
      min-height: calc(100vh - 140px);
      .tab {
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        cursor: pointer;
        box-sizing: border-box;
        &.active {
          color: #096BED;
          font-weight: 500;
          background-color: rgba($color: #096BED, $alpha: .1);
          border-left: 2px solid#096BED;
        }
      }
    }
  }
  .role-rt {
    margin-left: 10px;
    flex: 1;
    .items {
      .items-head {
        border: 1px solid #E9E9E9;
        background-color: #E9E9E9;
        width: 100%;
        padding: 0 10px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
      }
      .items-body {
        border: 1px solid #E9E9E9;
        .item {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #E9E9E9;
          flex-shrink: 0;
        }
      }
      .operate {
        width: 200px;
        display: flex;
        color: #888888;
        >div {
          width: 100px;
          text-align: center;
        }
      }
    }
  }
}