.model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  &.min {
    width: calc(100% - 203px);
  }
  .model-box {
    background: #fff;
    position: relative;
    .close-icon {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      &:hover {
        opacity: .6;
      }
    }
    .model-box-header {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
      h6 {
        font-size: 16px;
      }
    }
  }
}
.rndModal {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  height: 100%;
  background: #fff;
  .head {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #e8e8e8;
    h6 {
      font-size: 14px;
    }
    i {
      cursor: pointer;
    }
  }
}